@import '~antd/dist/antd.compact.less';




@primary-color: #3D9BE9;

img {
  display: unset !important;
}

svg {
  vertical-align: initial;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: 'Be Vietnam Pro', 'Kantumruy';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

